import React, { useEffect } from 'react';
import App, { AppProps } from 'next/app';
import '../src/index.scss';
import { AuthProvider } from '~/store/user';
import { AuthState } from '~/types/AuthState';
import { PosthogInit } from '~/components/organisms/PosthogInit';
import Head from 'next/head';
import { analytics } from '~/store/analytics';
import { UserCache } from '~/store/userCache';
import { ProposalCache } from '~/store/proposalCache';
import { getSessionData } from '~/util/djangoSession';
import { GlobalConfig } from '~/globals';
import { RoseErrorBoundary } from '~/components/organisms/ErrorBoundary/RoseErrorBoundary';
import { Theme, themeDefault } from '~/context/theme';

// globalThis polyfill - wouldn't bother normally, but it seems to affect
// a lot of legit content crawlers
// eslint-disable-next-line import/no-extraneous-dependencies
import '@ungap/global-this';

interface Props {
  userInitial: AuthState;
  config: GlobalConfig;
}

const userCache = new UserCache();
globalThis.Rose.userCache = userCache;
const proposalCache = new ProposalCache();
globalThis.Rose.proposalCache = proposalCache;

export default function MyApp({ Component, pageProps, router }: AppProps<Props>) {
  const { userInitial, config, ...props } = pageProps;
  const { isReady, pathname } = router;
  globalThis.Rose.config = globalThis.Rose.config || {};
  for (const key of Object.keys(config)) {
    if (config[key]) {
      globalThis.Rose.config[key] = config[key];
    }
  }

  useEffect(() => {
    if (isReady) {
      analytics.pageview();
    }
  }, [isReady, pathname]);

  return (
    <Theme.Provider value={themeDefault}>
      <AuthProvider initialState={userInitial}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <PosthogInit />
        <RoseErrorBoundary>
          <div>
            <Component {...props} />
          </div>
        </RoseErrorBoundary>
      </AuthProvider>
    </Theme.Provider>
  );
}

MyApp.getInitialProps = async (appContext: any) => {
  const { req } = appContext.ctx;
  const isServer = !!req;
  const appProps = await App.getInitialProps(appContext);

  if (isServer) {
    if (!process.env.SESSIONS_KEY) {
      console.error(
        'WARNING: The SESSIONS_KEY environment is not set. Without this, session support will not work.'
      );
      return appProps;
    }

    const session = req?.cookies?.sessionid;
    const csrfToken = req?.cookies?.csrftoken;

    if (session) {
      const data = getSessionData(session, process.env.SESSIONS_KEY);
      if (!data) {
        console.warn(
          'WARNING: Loaded session data is empty. The SESSIONS_KEY environment variable may be set incorrectly.'
        );
        appProps.pageProps.userInitial = { auth: false, cached: false, csrfToken };
      } else {
        appProps.pageProps.userInitial = {
          cached: true,
          auth: true,
          user: data,
          csrfToken,
        };
      }
    } else {
      appProps.pageProps.userInitial = { auth: false, cached: false, csrfToken };
    }
  }

  // using a variable works around nextjs's env replacement at build time
  const runtimeKeys = [
    'GOOGLE_API_FRONTEND_KEY',
    'NEXT_PUBLIC_ANNOUNCE_BOX_ANNOUNCEMENT',
    'NEXT_PUBLIC_ANNOUNCE_BOX_ENABLED',
    'NEXT_PUBLIC_POSTHOG_KEY',
    'NEXT_PUBLIC_STRIPE_KEY',
  ];

  const config: GlobalConfig = {};
  for (const key of runtimeKeys) {
    config[key] = process.env[key];
  }

  appProps.pageProps.config = config;

  return appProps;
};
