import { inflate } from 'pako';
import { TimestampSigner } from './signing/timestampSigner';
import { ICachedUser } from '~/types/AuthState';
import { UserRole } from '~/model/UserInfo';

function handleImpersonationUser(sessionData: any) {
  if (!sessionData) {
    return {};
  }

  if (sessionData.is_authenticated && sessionData.role === UserRole.Admin) {
    if (sessionData._impersonate_user) {
      sessionData = sessionData._impersonate_user;
    }
  }

  return sessionData;
}

/*
  Port of Django's functionality to read session data out of a cookie

  See https://github.com/django/django/blob/b8738aea14446b267a47087b52b38a98b440a6aa/django/core/signing.py#L265
  for more
 */
export function getSessionData(sessionCookieData: string, secretKey: string): ICachedUser | null {
  const signer = new TimestampSigner(
    secretKey,
    ':',
    'django.contrib.sessions.backends.signed_cookies'
  );
  try {
    let value = signer.unsign(sessionCookieData);
    const isCompressed = value[0] === '.';
    if (isCompressed) {
      value = value.slice(1);
    }
    if (isCompressed) {
      value = inflate(Buffer.from(value, 'base64url'), { to: 'string' });
    } else {
      value = Buffer.from(value, 'base64url').toString('utf8');
    }

    return handleImpersonationUser(JSON.parse(value));
  } catch (error) {
    // If there's an invalid session cookie, ignore it and treat as not logged in
    return null;
  }
}
